import React from 'react';

import Router from './routes/Router';

// SASS Importing
import './App.scss';

export const App = (props) => {
  return (
    <>
      <Router />
    </>
  );
};

export default App;
