import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loader from '../components/loader/Loader';
// import Main from '../views/Main';
// import MainArtists from '../views/MainArtists';

// import ThanksYoupage from '../thanksYouPage/ThanksYoupage';
// import InterviewPage from '../interviewPage/InterviewPage';
// import PaseoVirtual from '../paseoVirtual/PaseoVirtual';
// import ThanksYoupageReservation from '../thanksYouPageReservation/ThanksYoupage';

const Main = React.lazy(() => import('../views/Main'));
const MainArtists = React.lazy(() => import('../views/MainArtists'));
const ThanksYoupage = React.lazy(() =>
  import('../thanksYouPage/ThanksYoupage')
);
const InterviewPage = React.lazy(() =>
  import('../interviewPage/InterviewPage')
);
const PaseoVirtual = React.lazy(() => import('../paseoVirtual/PaseoVirtual'));
const ThanksYoupageReservation = React.lazy(() =>
  import('../thanksYouPageReservation/ThanksYoupage')
);

export const Router = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          // component={Main}
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <Main {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/bf"
          // component={Main}
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <Main {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/curso"
          // component={Main}
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <MainArtists {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/bf-curso"
          // component={Main}
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <MainArtists {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/artistas"
          // component={Main}
        >
          <Redirect
            to={
              window.location.search !== ''
                ? '/curso' + window.location.search
                : '/curso'
            }
          />
        </Route>
        <Route
          exact
          path="/paseo-virtual"
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <PaseoVirtual {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/nft-mastery-purchased"
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <ThanksYoupage {...props} />
            </Suspense>
          )}
        />

        <Route
          exact
          path="/entrevista"
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <InterviewPage {...props} />
            </Suspense>
          )}
        />
        <Route
          exact
          path="/gracias"
          render={(props) => (
            <Suspense fallback={<Loader />}>
              <ThanksYoupageReservation {...props} />
            </Suspense>
          )}
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
